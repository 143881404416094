import React from 'react'
import { numberToFixedString } from '../../services/Format/numberToFixedString'
import { Average, Circle, CircleBg, Empty, Points } from './ProgressRing.style'

export default class ProgressRing extends React.Component {
  constructor(props) {
    super(props)

    const { radius, stroke } = this.props

    this.normalizedRadius = radius - stroke * 2
    this.circumference = this.normalizedRadius * 2 * Math.PI

    this.state = {
      progress: 0
    }
  }

  componentWillReceiveProps(nextProps) {
      this.setState({ progress: (this.props.average * 100) / 5 })
  }
  

  render() {
    const { radius, stroke, average } = this.props
    
    let averageText = '';
    if(average !== null){
        averageText = numberToFixedString(average, 1)
    }
    const strokeDashoffset =
      this.circumference - (this.state.progress / 100) * this.circumference

    return (
      <Average>
        <svg height={radius * 2} width={radius * 2}>
          <CircleBg
            strokeWidth={stroke}
            strokeDasharray={this.circumference + ' ' + this.circumference}
            style={{ strokeDashoffset: 0 }}
            stroke-width={stroke}
            r={this.normalizedRadius}
            cx={radius}
            cy={radius}
          />
          <Circle
            strokeWidth={stroke}
            strokeDasharray={this.circumference + ' ' + this.circumference}
            style={{ strokeDashoffset }}
            stroke-width={stroke}
            r={this.normalizedRadius}
            cx={radius}
            cy={radius}
          />
        </svg>
        <Points>{average ? averageText + "/5" : <Empty>Brak ocen</Empty>}</Points>
      </Average>
    )
  }
}
