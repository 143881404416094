import styled from 'styled-components'

import { color } from '../../assets/styles/variables'

export const Average = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
`
export const Circle = styled.circle`
  transition: stroke-dashoffset 1.4s ease;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  stroke: ${color.progressBar};
  fill: transparent;
`

export const CircleBg = styled.circle`
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  stroke: #f2e9e1;
  fill: #FFF;
`

export const Points = styled.div`
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: block;
  color: #053592;
  font-weight: 700;
  z-index: 100;
  font-size: 20px;
  line-height: 20px;
`

export const Empty = styled.div`
font-size: 14px;
font-weight: 400;
`
