import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { graphql } from 'gatsby'
import config from '../../config.json'
import PinImg from '../assets/images/icons/pin.svg'
import WeightImg from '../assets/images/icons/weight-icon.png'
import Button from '../components/Button/Button'
import { CustomContainer } from '../components/Hero/Hero.style'
import Layout from '../components/Layout/Layout'
import LastPosts from '../components/Posts/LastPosts'
import ProgressRing from '../components/ProgressRing/ProgressRing'
import { Result } from '../components/Ranking/RankingItem.style'
import Seo from '../components/Seo/Seo'
import Opinion from '../components/Slide/Opinion'
import Slide from '../components/Slide/Slide'
import Stars from '../components/Stars/Stars'
import StickyBox from '../components/StickyBox/StickyBox'
import { HeadingH2, Wrapper } from '../components/StickyBox/StickyBox.style'
import { useElementOnScreen } from '../hooks/useElementOnScreen'
import { numberToFixedString } from '../services/Format/numberToFixedString'
import {
  Address,
  Box,
  ChartBox,
  Checked,
  Description,
  LogoWrapper,
  NumberTop,
  Title,
  TitleArticle,
} from './insuranceCompany.style'
import { ArticleContent, Content } from './post.style'

export default function InsurenceCompany({ pageContext, data }) {
  const seo = data.wpPost.seo
  const companyWordpress = pageContext.node.insurance_company[0].wordpress_details
  const companyBasic = pageContext.node.insurance_company[1]
  const tuAverage = pageContext.node.insurance_company[1].tu_average
  const opinionsArray = pageContext.node.opinions
  const websites = pageContext.node.websites
  const average = numberToFixedString(tuAverage, 1)

  const chartConfig = {
    radius: 60,
    stroke: 6,
  }

  const findAndGetWebsiteAverage = (websiteKey) => {
    const websiteAverage = websites.find((website) => website.website_key === websiteKey)
    return websiteAverage && websiteAverage.opinions_average ? websiteAverage.opinions_average : null
  }

  const googleAverage = findAndGetWebsiteAverage('google')
  const absolventAverage = findAndGetWebsiteAverage('absolvent')
  const facebookAverage = findAndGetWebsiteAverage('facebook')
  const panoramafirmAverage = findAndGetWebsiteAverage('panorama_firm')
  const goworkAverage = findAndGetWebsiteAverage('go_work')
  const opinie24Average = findAndGetWebsiteAverage('opinie_24')

  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.25,
  })

  let formButton;
  let isLife = false; let isHousehold = false; let isHealth = false;
  let calcLink = config.CALC_LINK_LIFE;
  let insuranceTypeText;

  if (companyWordpress.ranking_type === 'ubezpieczenia-na-zycie') {
    if (Boolean(companyWordpress.form_url)) {
      formButton = <Button width="auto" text="Porównaj ubezpieczenia" externalLink={companyWordpress.form_url} />
    } else {
      formButton = <Button width="auto" text="Porównaj ubezpieczenia" externalLink={config.CALC_LINK_LIFE} />
    }

    isLife = true;
    insuranceTypeText = "ubezpieczenia na życie";
  }
  if (companyWordpress.ranking_type === 'ubezpieczenia-majatkowe') {
    if (Boolean(companyWordpress.form_url)) {
      formButton = <Button width="auto" text="Porównaj ubezpieczenia" externalLink={companyWordpress.form_url} />
    } else {
      formButton = <Button width="auto" text="Porównaj ubezpieczenia" externalLink={config.CALC_LINK_HH} />
    }

    isHousehold = true;
    insuranceTypeText = "ubezpieczenia nieruchomości";
  }
  if (companyWordpress.ranking_type === 'ubezpieczenia-zdrowotne') {
    if (Boolean(companyWordpress.form_url)) {
      formButton = <Button width="auto" text="Porównaj ubezpieczenia" externalLink={companyWordpress.form_url} />
    } else {
      formButton = <Button width="auto" text="Porównaj ubezpieczenia" externalLink={config.CALC_LINK_HEALTH} />
    }

    isHealth = true;
    insuranceTypeText = "ubezpieczenia zdrowotne";
  }

  if(isHousehold){
    calcLink = config.CALC_LINK_HH;
  }
  if(isHealth){
    calcLink = config.CALC_LINK_HEALTH;
  }
  return (
    <Layout>
      <Seo title={seo.title} description={seo.metaDesc} keywords={seo.focuskw} pathname={seo.opengraphUrl} />
      <CustomContainer>
        <Row>
          <Col md={6} className="d-flex  align-items-center mb-2">
            <div style={{ display: 'block' }}>
              <Title>
                {companyWordpress.post_title ? companyWordpress.post_title : companyWordpress.insurance_company}
              </Title>
              <div className="d-flex">
                <Result style={{ padding: '10px 20px 10px 20px', marginRight: 20 }}>
                  {new Intl.NumberFormat('pl-PL', {minimumFractionDigits: 1}).format(average)}/5
                </Result>
                <Stars rating={average} opinions={companyBasic.opinions_count} />
              </div>
              <div>
                <Address>
                  <img src={PinImg} alt="" style={{ width: '20px', marginRight: 5 }} />
                  {`ul. ${companyBasic.street}, ${companyBasic.postal_code} ${companyBasic.city}`}
                </Address>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: companyWordpress.smart_description,
                }}
                className="mt-4 font-weight-normal"
              />
            </div>
          </Col>
          <Col md={6} className="order-first order-md-last align-items-center d-flex">
            <div className="p-2 p-lg-0">
              <StaticImage src="../assets/images/company/bg.png" quality={90} alt="Polisa ubezpieczeniowa" />
            </div>
          </Col>
        </Row>
        <Row>
          <div className="col-lg-12 col-xl-8">
            <ArticleContent style={{ position: 'relative' }}>
              <div className="company-content">
                <div className="box row">
                  <div className="col-lg-7">
                    <TitleArticle>
                      <NumberTop>{companyWordpress.position_number}</NumberTop>
                      <h2>{companyWordpress.insurance_company}</h2>
                    </TitleArticle>
                  </div>
                  <div className="col-lg-5 d-flex align-items-center  justify-content-center justify-content-lg-end my-3 my-lg-0">
                    {formButton}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-4 col-md-12 offset-lg-1 px-lg-4 align-items-center align-items-lg-start d-flex flex-column">
                    <Checked>{companyWordpress.position_number}</Checked>
                    <Description>Pozycja rynkowa w Polsce</Description>
                  </div>
                  <div className="col-lg-5 col-md-12 align-items-center align-items-lg-start d-flex flex-column">
                    <Checked>{companyWordpress.damages_sum} mln</Checked>
                    <Description>Wypłacone odszkodowania (w ub. roku)</Description>
                  </div>
                </div>
                <Row className="my-5">
                  <Col>
                    <Box>
                      <div className="row" ref={containerRef}>
                        <Col lg={2} md={6} sm={12}>
                          <ChartBox>
                            <LogoWrapper>
                              <StaticImage
                                src="../assets/images/company/google.png"
                                quality={90}
                                alt="Logo Google"
                                title="Google"
                              />
                            </LogoWrapper>
                            {}
                            <ProgressRing average={googleAverage} isVisible={isVisible} {...chartConfig} />
                          </ChartBox>
                        </Col>
                        <Col lg={2} md={6} sm={12}>
                          <ChartBox>
                            <LogoWrapper>
                              <StaticImage
                                src="../assets/images/company/facebook.png"
                                quality={90}
                                alt="Logo Facebook"
                                title="Facebook"
                              />
                            </LogoWrapper>
                            <ProgressRing average={facebookAverage} isVisible={isVisible} {...chartConfig} />
                          </ChartBox>
                        </Col>
                        <Col lg={2} md={6} sm={12}>
                          <ChartBox>
                            <LogoWrapper>
                              <StaticImage
                                src="../assets/images/company/absolvent.png"
                                quality={90}
                                alt="Logo Absolvent"
                                title="Absolvent"
                              />
                            </LogoWrapper>
                            <ProgressRing average={absolventAverage} isVisible={isVisible} {...chartConfig} />
                          </ChartBox>
                        </Col>
                        <Col lg={2} md={6} sm={12}>
                          <ChartBox>
                            <LogoWrapper>
                              <StaticImage
                                src="../assets/images/company/panoramafirm.png"
                                quality={90}
                                alt="Logo Panorama Firm"
                                title="Panorama Firm"
                              />
                            </LogoWrapper>
                            <ProgressRing average={panoramafirmAverage} isVisible={isVisible} {...chartConfig} />
                          </ChartBox>
                        </Col>
                        <Col lg={2} md={6} sm={12}>
                          <ChartBox>
                            <LogoWrapper>
                              <StaticImage
                                src="../assets/images/company/gowork.png"
                                quality={90}
                                alt="Logo Gowork"
                                title="Gowork"
                              />
                            </LogoWrapper>
                            <ProgressRing average={goworkAverage} isVisible={isVisible} {...chartConfig} />
                          </ChartBox>
                        </Col>
                        <Col lg={2} md={6} sm={12}>
                          <ChartBox>
                            <LogoWrapper>
                              <StaticImage
                                src="../assets/images/company/opinie24.png"
                                quality={90}
                                alt="Logo Opinie24"
                                title="Opinie24"
                              />
                            </LogoWrapper>
                            <ProgressRing average={opinie24Average} isVisible={isVisible} {...chartConfig} />
                          </ChartBox>
                        </Col>
                      </div>
                    </Box>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Content
                      dangerouslySetInnerHTML={{
                        __html: companyWordpress.full_description,
                      }}
                      className="mt-4 font-weight-normal"
                    />
                  </Col>
                </Row>
              </div>
            </ArticleContent>
            <Row>
              <Col>
                <Wrapper style={{ textAlign: 'center' }}>
                  <img className="mb-3 image" src={WeightImg} alt="Ikona waga" title="Porównaj oferty ubezpieczeń" />
                  <HeadingH2>
                    Chcesz porównać {insuranceTypeText} <br/> z najlepszych Towarzystw?
                  </HeadingH2>
                  <div
                    style={{
                      background: '#FFF',
                      width: 100,
                      height: 2,
                      margin: '0 auto',
                    }}></div>
                  <div style={{ fontWeight: 500, color: '#FFF' }} className="my-4">
                    Wybierz dobrą polisę i nie przepłacaj!
                  </div>

                  <Button externalLink={calcLink} text="Porównaj oferty ubezpieczeń" />
                </Wrapper>
              </Col>
            </Row>
            <Row>
              <Slide isVertical={true} heading="Opinie">
                {opinionsArray.map((opinion, i) => {
                  return (
                    <Opinion
                      author={opinion.author}
                      comment={opinion.comment}
                      publishedDate={opinion.published_date}
                      rating={opinion.rating}
                      websiteKey={opinion.website_key}
                      isVertical={true}
                      commentMinHeight="50px"
                      key={i}
                    />
                  )
                })}
              </Slide>
            </Row>
          </div>
          <Col lg={4} style={{ paddingTop: 40 }} className="d-none d-xl-block">
            <StickyBox isLife={isLife} isHousehold={isHousehold} isHealth={isHealth} />
          </Col>
        </Row>
        <Row>
          <Col>
            <LastPosts />
          </Col>
        </Row>
      </CustomContainer>
    </Layout>
  )
}

export const query = graphql`
  query InsuranceCompanySeo($slug: String) {
    wpPost(slug: { eq: $slug }) {
      seo {
        focuskw
        metaDesc
        title
      }
      slug
    }
  }
`
